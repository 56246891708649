import _ from 'lodash'
import moment from 'moment'
import { Component } from 'vue-property-decorator'
import Router from 'vue-router'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'
import { locales, getLocaleFromCode, getLocaleFromUnderscored, LocaleData } from '@/edshed-common/i18n/locale-map'
import config from '@/config'
import { getAllPlans } from '@/edshed-common/subscriptionPackages'
import { Currency } from '@/edshed-common/api/types/currency'
import { Buildable } from 'ts-essentials'

type Dictionary<T> = { [key: string]: T }

@Component
export default class ComponentHelper extends ComponentHelperBase {
  protected get locale () {
    return this.$i18n.locale || 'en-gb'
  }

  protected get locales () {
    return locales
  }

  protected get localeData () {
    return getLocaleFromCode(this.locale)
  }

  protected get config () {
    return config
  }

  protected get allPlans () {
    return getAllPlans(this.$store.state.api_environment)
  }

  protected pageUrl (pagePath: string) {
    if (!pagePath) {
      return `/${this.locale}`
    }

    return `/${this.locale}/${pagePath}`
  }

  protected stripHtml (input: string) {
    const pattern = /<[a-z/]+>/g
    return input.replace(pattern, '')
  }

  protected dateFormat (dateString: string) {
    return moment(dateString).format('DD/MM/YYYY HH:mm')
  }

  protected truncate (input: string, length = 50) {
    if (input.length <= length) { return input }

    return input.slice(0, length).trim() + '…'
  }

  protected shuffleArray (array: Array<any>) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  protected i18nLocaleFormat (str: string): 'en_GB' | 'en_US' {
    const split = str.split('-')
    if (split[1]) { split[1] = split[1].toUpperCase() }
    return split.join('_') as 'en_GB' | 'en_US'
  }

  protected localeLanguageName (str: string) {
    const localeData = getLocaleFromUnderscored(str)
    return localeData.languageName
  }

  protected localeFlagImg (str: string) {
    const localeData = getLocaleFromUnderscored(str)
    return localeData.svg
  }

  protected sortLocales<T extends keyof LocaleData> (a: LocaleData, b: LocaleData, field: T, dir: 'asc' | 'desc' = 'asc') {
    return (a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0) * (dir === 'asc' ? 1 : -1)
  }

  /** User's preferred locale */
  protected get userLocale () {
    return this.$store.state.user ? this.$store.state.user.locale : null
  }

  protected humanise (input: string) {
    return _.startCase(input)
  }

  protected justDateFormat (dateString: string) {
    let dateFormatString = 'DD/MM/YYYY'
    if (this.locale === 'en-us') {
      dateFormatString = 'MM/DD/YYYY'
    }

    return moment(dateString).format(dateFormatString)
  }

  protected justDateFromUnix (timestamp: number) {
    let dateFormatString = 'DD/MM/YYYY'

    if (this.locale === 'en-us') {
      dateFormatString = 'MM/DD/YYYY'
    }

    return moment.unix(timestamp).format(dateFormatString)
  }

  // postpend an s on the end if the quantity is not equal to 1
  public pluralise (quantity: number, plural = 's') {
    return quantity === 1 ? '' : plural
  }

  public capitaliseFirstLetter (str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  public notNull<T> (val: T | null): val is T {
    return val !== null
  }

  public ordinalSuffixOf (number: number) {
    const x = number % 10
    const y = number % 100
    if (x === 1 && y !== 11) {
      return number + 'st'
    }
    if (x === 2 && y !== 12) {
      return number + 'nd'
    }
    if (x === 3 && y !== 13) {
      return number + 'rd'
    }
    return number + 'th'
  }

  public formatCurrency (value: number, currency: string) {
    return Intl.NumberFormat(this.localeData.iso, {
      style: 'currency',
      currency
    }).format(value)
  }

  getCurrencySymbolFromISO (curr: Currency) {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: curr
    })

    const parts = formatter.formatToParts(1000)

    const currencySymbol = parts.find(part => part.type === 'currency')

    if (currencySymbol) {
      return currencySymbol.value
    }

    throw new Error('Could not format currency')
  }

  getCurrencyNameFromISO (curr: Currency) {
    // @ts-ignore
    const formatter = new Intl.DisplayNames(undefined, {
      type: 'currency'
    })

    const currencyTitle = formatter.of(curr)

    return currencyTitle
  }

  stripUndefProps<T extends {}> (obj: T): Required<T> {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') { this.stripUndefProps(obj[key]) } else if (obj[key] === undefined) { delete obj[key] }
    })
    return obj as Required<T>
  }

  stringifyProps (obj: {[key: string]: {
    toString(): string;
  }}): {[key: string]: string} {
    const result: Buildable<{[key: string]: string}> = {}

    for (const key in obj) {
      const val = obj[key]

      if (val instanceof Date) {
        result[key] = val.getTime().toString()
      } else {
        result[key] = obj[key].toString()
      }
    }

    return result as {[key: string]: string}
  }

  public async updateQueryParams (queries: () => Dictionary<string | (string | null)[]>) {
    try {
      await this.$router.replace({ query: queries() })
    } catch (err: unknown) {
      if (Router.isNavigationFailure(err, Router.NavigationFailureType.cancelled)) {
        setTimeout(() => {
          this.updateQueryParams(queries)
        }, 100)
      }
    }
  }

  checkStringIsInUnion<S extends string> (str: string, union: readonly S[] | S[]): str is S {
    return union.includes(str as S)
  }

  parseQueryEnum<T extends string> (query: string, union: readonly T[] | T[]): T[] | null {
    if (this.$route.query[query]) {
      const filterArray: T[] = []
      const val = this.$route.query[query]
      const options = typeof val === 'string' ? val.split(',') : val

      options.forEach((e) => {
        if (typeof e === 'string' && this.checkStringIsInUnion(e, union)) {
          filterArray.push(e)
        }
      })

      return filterArray
    } else {
      return null
    }
  }

  protected toastContainer: string | undefined = undefined

  toastError (message: string) {
    this.$buefy.toast.open({
      message,
      position: 'is-bottom',
      type: 'is-danger',
      container: this.toastContainer
    })
  }
}
