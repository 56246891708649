export const Currency = ['gbp', 'usd', 'aud', 'nzd', 'eur', 'zar'] as const
export type Currency = typeof Currency[number]

export const DomesticCurrency = ['gbp', 'eur', 'usd'] as const
export type DomesticCurrency = typeof DomesticCurrency[number]

export function symbolIconForCurrency (c: Currency) {
  switch (c) {
    case 'gbp':
      return 'currency-gbp'
    case 'aud':
      return 'currency-usd'
    case 'nzd':
      return 'currency-usd'
    case 'usd':
      return 'currency-usd'
    case 'eur':
      return 'currency-eur'
    case 'zar':
      return 'alpha-r'
  }
}

export function symbolSizeForCurrency (c: Currency) {
  switch (c) {
    case 'zar':
      return 'is-medium'
  }

  return undefined
}
