






























































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { CountryCode, getAvailableStockForAddress, getShippingDetailsForAddress, ImageUpload, MerchBundleStub, merchBundleStubFromStoreItem, StoreItem, StoreItemCategory, StoreItemSubject, ZoneCode } from '../api'
import ComponentHelperBase from '../mixins/ComponentHelperBase'
import BundleView from './BundleView.vue'

@Component({
  name: 'StoreItemView',
  components: { BundleView }
})
export default class StoreItemView extends Mixins(ComponentHelperBase) {
  @Prop({ required: true }) product!: StoreItem & { new_image?: ImageUpload | null }

  @Prop({ required: true }) isAdmin!: boolean

  @Prop({ required: true }) shippingCountry!: CountryCode

  @Prop({ required: true }) shippingZone!: ZoneCode | null

  @Prop({ default: true }) allowAddCart!: boolean

  @Prop({ default: 'is-top' }) toolTipDirection!: string

  @Prop({ default: null }) highlight!: string | null

  @Prop({ default: 0 }) cartQty!: number

  private quantity: number = 1

  private rerenderCount: number = 0

  private showDetailed: boolean = false

  private bundleToDisplay: MerchBundleStub | null = null

  @Watch('quantity')
  onQuantityChanged () {
    this.$emit('quantity-changed', this.quantity)
  }

  mounted () {

  }

  imageAdded () {
    this.rerenderCount++
  }

  get shippingDetails () {
    return getShippingDetailsForAddress(this.product, { country_code: this.shippingCountry, zone_code: this.shippingZone })

    // throw new Error('Product does not have shipping details!')
  }

  get imageSrc () {
    if (this.rerenderCount < 0) {
      return ''
    }

    if (this.product.new_image) {
      return this.product.new_image.data
    }

    if (this.product.image) {
      return this.product.image.fullSizePath
    }

    return ''
  }

  getTagTypeForSubject (subject: StoreItemSubject) {
    switch (subject) {
      case 'number':
        return 'is-number'
      case 'spelling':
        return 'is-warning'
      case 'literacy':
        return 'is-dark'
      case 'phonics':
        return 'is-phonics'
    }
  }

  getTagTextForSubject (subject: StoreItemSubject) {
    switch (subject) {
      case 'number':
        return this.$t('Maths')
      case 'spelling':
        return 'Spelling'
      case 'literacy':
        return 'Literacy'
      case 'phonics':
        return 'Phonics'
    }
  }

  getTagTypeForCategory (category: StoreItemCategory) {
    switch (category) {
      case 'merch':
        return 'is-info'
      case 'subscription':
        return 'is-success'
      case 'resources':
        return 'is-danger'
      case 'training':
        return 'is-dark'
      case 'bundle':
        return 'is-link'
    }
  }

  getTagTextForCategory (category: StoreItemCategory) {
    switch (category) {
      case 'merch':
        return 'Merchandise'
      case 'subscription':
        return 'Subscription'
      case 'resources':
        return 'Resources'
      case 'training':
        return 'Training'
      case 'bundle':
        return 'Bundle'
    }
  }

  get available () {
    return getAvailableStockForAddress(this.product, { country_code: this.shippingCountry, zone_code: this.shippingZone })
  }

  get isOutOfStock () {
    if (this.available === undefined) {
      return true
    }

    return this.available <= 0
  }

  get hasLowStock () {
    return this.available !== undefined && this.available <= 10
  }

  displayBundle () {
    this.bundleToDisplay = merchBundleStubFromStoreItem(this.product)
  }
}
