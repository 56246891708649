






















































































































































































































































































































































































































































































































































































import { AddMerchProductOptions, Api, calculateBundleStock, calculateUnitBundleTax, CountryCode, CountryInfo, CountryZoneInfo, createAddrFromShippingCode, getShippingDetailsForAddress, ImageUpload, isZoneCode, MerchBundleComponent, MerchLocationInfo, MerchStoreCurrency, MerchStoreShippingLocale, merchStoreTaxRates, ShippingCode, StoreItem, StoreItemCategory, StoreItemSubject, StoreItemSubscriptionTrigger, StripeInvoiceAccountRegion, SubscriptionPlanInfo, ZoneCode } from '@/edshed-common/api'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'
import StoreItemView from '@/edshed-common/components/StoreProduct.vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import ImageInput from '@/edshed-common/components/ImageInput.vue'
import _ from 'lodash'
import LookupInput from '@/edshed-common/components/LookupInput.vue'
import debounce from 'lodash/debounce'
import MerchSelectBasePlan from './MerchSelectBasePlan.vue'

function emptyProduct (): AddMerchProductOptions {
  return {
    sku: '',
    name: '',
    description: '',
    active: true,
    detail: '',
    weight: 0,
    categories: [],
    subjects: [],
    require_school: false,
    shipping_locales: [],
    plan_id: null,
    subscription_trigger: null,
    account_region: 'GB'
  }
}

const globalCountry: CountryInfo = {
  id: 0,
  code: 'any' as CountryCode,
  name: 'Global',
  tax_rate: 1.2,
  reverse_charge: false,
  default_locale: 'en_GB',
  default_currency: 'gbp',
  accepted_currencies: ['gbp'],
  roster_options: []
}

type Mutable<Type> = {
  -readonly [Key in keyof Type]: Type[Key];
};

@Component({
  name: 'AddMerchProduct',
  components: {
    StoreItemView,
    ImageInput,
    LookupInput,
    MerchSelectBasePlan
  }
})
export default class AddMerchProduct extends Mixins(ComponentHelperBase) {
  @Prop({ default: null })
  editProduct!: StoreItem | null

  product: Mutable<AddMerchProductOptions> = { ...emptyProduct(), shipping_locales: [] }

  private bundleComponents: MerchBundleComponent[] = []

  private countries: CountryInfo[] = []

  private activeStep: number = 0

  private zones: CountryZoneInfo[] = []

  private countrySearch: string = ''

  private addLocaleCountrySearch: string = ''

  private addLocaleZoneSearch: string = ''

  private countryPreviewSearch: string = ''

  private zonePreviewSearch: string = ''

  private shippingCountryPreview: CountryCode | 'any' | null = null

  private shippingZonePreview: ZoneCode | null = null

  private rerenderCount: number = 0

  private filteredCategories: StoreItemCategory[] = [...StoreItemCategory]

  private filteredSubjects: StoreItemSubject[] = [...StoreItemSubject]

  private regions = StripeInvoiceAccountRegion

  private triggers = StoreItemSubscriptionTrigger

  private highlightedElement: string | null = null

  private localeToAdd: CountryInfo | null = null

  private zoneToAdd: CountryZoneInfo | null = null

  private currencies = MerchStoreCurrency

  private locationLookup: MerchLocationInfo | null = null

  private destinationTemplate: ShippingCode | 'any' | null = null

  private productToCopyFrom: StoreItem | null = null

  private addableComponentSearch: StoreItem[] = []

  private bundleComponentToAdd: StoreItem | null = null

  private searchingComponents: boolean = false

  private selectingBasePlan: boolean = false

  private plans: SubscriptionPlanInfo[] = []

  private merchPlanSelected: (plan: SubscriptionPlanInfo) => void = (_) => {}

  async mounted () {
    if (this.editProduct) {
      this.product = {
        sku: this.editProduct.sku,
        name: this.editProduct.name,
        description: this.editProduct.description,
        active: this.editProduct.active,
        detail: this.editProduct.detail,
        weight: this.editProduct.weight,
        categories: [...this.editProduct.categories],
        subjects: [...this.editProduct.subjects],
        require_school: this.editProduct.require_school,
        shipping_locales: this.editProduct.shipping_locales.map(l => ({ ...l })),
        plan_id: this.editProduct.plan_id,
        subscription_trigger: this.editProduct.subscription_trigger,
        account_region: this.editProduct.account_region
      }

      this.bundleComponents = this.editProduct.bundle_components ? _.cloneDeep(this.editProduct.bundle_components) : []

      const plansToLoad: number[] = []

      if (this.editProduct.plan_id) {
        plansToLoad.push(this.editProduct.plan_id)
      }

      plansToLoad.push(...this.editProduct.shipping_locales.filter(l => l.plan_id).map(l => l.plan_id!))

      if (plansToLoad.length > 0) {
        this.plans = await Api.getSubscriptionPlans({
          id: plansToLoad
        }, undefined)
      }
    }

    this.countries = [globalCountry, ...(await Api.getCountries())]
    this.zones = await Api.getCountriesZones(null)

    if (this.product.shipping_locales.find(l => l.locale === 'any')) {
      this.shippingCountryPreview = globalCountry.code
    } else if (this.product.shipping_locales.length > 0 && !isZoneCode(this.product.shipping_locales[0].locale)) {
      this.shippingCountryPreview = this.product.shipping_locales[0].locale
    }

    this.countrySearch = this.countries.find(c => c.code === this.shippingCountryPreview)?.name || ''
  }

  public async save () {
    const shippingLocales = this.product.shipping_locales.filter(l => this.doAllChildrenHaveShippingRule(l.locale)).map((l) => {
      const tax = this.isBundle ? this.nonTaxRate(l) : l.xero_tax

      if (!tax) {
        throw new Error('No tax rate')
      }

      return {
        ...l,
        tax_exemptable: this.isBundle ? false : l.tax_exemptable,
        xero_tax: tax
      }
    })

    if (this.isBundle && (this.bundleComponents.length === 0)) {
      throw new Error('No components defined')
    }

    try {
      if (this.editProduct) {
        const updatedProduct = await Api.editMerchStoreItem(this.editProduct.id, { ...this.product, shipping_locales: shippingLocales, bundle_components: this.bundleComponents })
        this.$emit('product-changed', updatedProduct)
      } else {
        const addedProduct = await Api.addMerchStoreItem({ ...this.product, shipping_locales: shippingLocales, bundle_components: this.bundleComponents })
        this.$emit('product-created', addedProduct)
      }

      this.$buefy.toast.open({
        message: 'Product saved',
        type: 'is-success'
      })

      this.$emit('saved')
    } catch (err) {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Error saving product',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async updateProductStock (productId: number, locationId: number, quantity: number) {
    try {
      if (!this.editProduct) {
        throw new Error('error')
      }

      const updatedProduct = await Api.updateMerchStock(productId, { location_id: locationId, quantity })
      this.$emit('product-changed', updatedProduct)

      this.$buefy.toast.open({
        message: 'Stock updated',
        type: 'is-success'
      })
    } catch (err) {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Error saving Stock',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  hasImageChanged: boolean = false

  @Watch('needsSave', { immediate: true })
  needsSaveChanged (val: boolean) {
    this.$emit('needs-save', val)
  }

  setDestinationToCopy (code: ShippingCode | 'any') {
    this.destinationTemplate = code

    this.$buefy.toast.open({
      duration: 3000,
      message: 'Shipping rule copied. Select destination to paste to.',
      position: 'is-bottom',
      type: 'is-info'
    })
  }

  getTriggerTitle (trigger: StoreItemSubscriptionTrigger) {
    switch (trigger) {
      case 'shipped':
        return 'Shipped'
      case 'paid':
        return 'Paid'
      case 'promised':
        return 'Confirmed'
      default:
        return trigger
    }
  }

  startSelectingPlanForProduct () {
    this.merchPlanSelected = (p) => {
      this.product.plan_id = p.id
      this.plans.push(p)
    }

    this.selectingBasePlan = true
  }

  startSelectingPlanForLocale (locale: MerchStoreShippingLocale) {
    this.merchPlanSelected = (p) => {
      locale.plan_id = p.id
      this.plans.push(p)
    }

    this.selectingBasePlan = true
  }

  public get needsSave () {
    console.log('calculating needs save')
    if (this.hasImageChanged) {
      return true
    }

    // necessary to destructure bundle_components for reactivity to work
    const { bundle_components, ...product } = this.product

    if (this.editProduct) {
      const { id, image, stock, bundle_components: originalComponents, ...editProd } = this.editProduct
      return !_.isEqual(editProd, product) || (!_.isEqual(originalComponents, this.bundleComponents) && this.product.categories.includes('bundle'))
    } else {
      return !_.isEqual(product, emptyProduct) || (this.bundleComponents.length > 0 && this.product.categories.includes('bundle'))
    }
  }

  get filteredCountries () {
    if (this.countrySearch.length < 2) {
      return this.countries
    }
    return this.countries.filter((c) => {
      return (
        c.name
          .toString()
          .toLowerCase()
          .includes(this.countrySearch.toLowerCase())
      )
    })
  }

  get filteredPreviewCountries () {
    if (this.countryPreviewSearch.length < 2) {
      return this.countries
    }
    return this.countries.filter((c) => {
      return (
        c.name
          .toString()
          .toLowerCase()
          .includes(this.countryPreviewSearch.toLowerCase())
      )
    })
  }

  get zonesPerCountry () {
    return _.groupBy(this.zones, 'country_id')
  }

  get zonesForCountry () {
    return this.zonesPerCountry[this.localeToAdd?.id] || []
  }

  get zonesForCountryPreview (): CountryZoneInfo[] {
    if (!this.shippingCountryPreview || this.countries.length === 0 || this.zones.length === 0) {
      return []
    }

    const countryDetails = this.countries.find(c => c.code === this.shippingCountryPreview)

    if (!countryDetails) {
      throw new Error('Could not find country')
    }

    return this.zones.filter(z => z.country_id === countryDetails.id)
  }

  imageReady () {
    (this.$refs.preview as StoreItemView).imageAdded()
    this.rerenderCount++
    this.hasImageChanged = this.product.new_image !== undefined
  }

  previewLocale (code: ShippingCode | 'any') {
    if (code === 'any') {
      this.shippingCountryPreview = 'any'
      this.shippingZonePreview = null

      this.countryPreviewSearch = 'Global'
      this.zonePreviewSearch = ''
    }

    if (isZoneCode(code)) {
      this.shippingZonePreview = code

      const zone = this.zones.find(z => z.code === code)

      if (!zone) {
        throw new Error('Zone not found')
      }

      const country = this.countries.find(c => c.id === zone.country_id)

      if (!country) {
        throw new Error('country not found')
      }

      this.shippingCountryPreview = country.code

      this.countryPreviewSearch = country.name
      this.zonePreviewSearch = zone.name
    } else {
      this.shippingCountryPreview = code
      this.shippingZonePreview = null

      const country = this.countries.find(c => c.code === code)

      if (!country) {
        throw new Error('country not found')
      }

      this.countryPreviewSearch = country.name
      this.zonePreviewSearch = ''
    }
  }

  get previewObject (): StoreItem & { new_image?: ImageUpload | null } {
    if (this.rerenderCount < 0) {
      throw new Error('error')
    }

    return { ...this.product, image: this.editProduct?.image ?? null, id: this.editProduct?.id ?? 0, shipping_locales: this.product?.shipping_locales?.map(l => ({ ...l, product_id: this.editProduct?.id ?? 0 })), stock: this.editProduct?.stock || [], bundle_components: !this.bundleComponents ? [] : this.bundleComponents.map(c => ({ ...c, product_id: 0 })) }
  }

  get unusedShippingLocales () {
    const unused = this.countries.filter(c => !this.product.shipping_locales.find(sl => sl.locale === c.code) || !this.zones.filter(z => z.country_id === c.id).every(z => this.product.shipping_locales.find(sl => sl.locale === z.code)))

    if (this.addLocaleCountrySearch.length < 2) {
      return unused.slice(0, 10)
    }
    return unused.filter((c) => {
      return (
        c.name
          .toString()
          .toLowerCase()
          .includes(this.addLocaleCountrySearch.toLowerCase())
      )
    }).slice(0, 10)
  }

  get filteredPreviewZones () {
    if (this.zonePreviewSearch.length < 2) {
      return this.zonesForCountryPreview.slice(0, 10)
    }
    return this.zonesForCountryPreview.filter((c) => {
      return (
        c.name
          .toString()
          .toLowerCase()
          .includes(this.zonePreviewSearch.toLowerCase())
      )
    }).slice(0, 10)
  }

  get unusedShippingZones () {
    const unused = this.zones.filter(z => !this.product.shipping_locales.find(sl => sl.locale === z.code))

    if (this.addLocaleZoneSearch.length < 2) {
      return unused.slice(0, 10)
    }
    return unused.filter((z) => {
      return (
        z.name
          .toString()
          .toLowerCase()
          .includes(this.addLocaleZoneSearch.toLowerCase())
      )
    }).slice(0, 10)
  }

  get isBundle () {
    return this.product.categories.includes('bundle')
  }

  get bundleStock () {
    if (!this.isBundle) {
      return []
    } else {
      return calculateBundleStock({ id: 0, name: this.product.name }, this.editProduct ? this.editProduct.stock : [], this.bundleComponents)
    }
  }

  nonTaxRate (shipping: Omit<MerchStoreShippingLocale, 'product_id'>) {
    return this.taxRatesForLocale(shipping).find(t => t.rate === 1)
  }

  taxRatesForLocale (locale: Omit<MerchStoreShippingLocale, 'product_id'>) {
    let region: StripeInvoiceAccountRegion = 'GB'

    if (isZoneCode(locale.locale)) {
      const zone = this.zones.find(z => z.code === locale.locale)
      const country = this.countries.find(c => c.id === zone?.country_id)

      region = country?.code === 'US' ? 'US' : 'GB'
    } else if (locale.locale === 'any') {
      region = 'GB'
    } else {
      const country = this.countries.find(c => c.code === locale.locale)

      region = country?.code === 'US' ? 'US' : 'GB'
    }

    return merchStoreTaxRates.filter(t => t.region === region)
  }

  getFilteredCategories (text: string) {
    this.filteredCategories = StoreItemCategory.filter((cat) => {
      return !this.product.categories.includes(cat) && (cat.toLowerCase().includes(text.toLowerCase()) || text === '')
    })
  }

  getFilteredSubjects (text: string) {
    this.filteredSubjects = StoreItemSubject.filter((sub) => {
      return !this.product.subjects.includes(sub) && (sub.toLowerCase().includes(text.toLowerCase()) || text === '')
    })
  }

  addShippingLocale (useTemplate: boolean) {
    if (!this.localeToAdd) {
      throw new Error('Locale not selected')
    }

    const shippingCode = this.zoneToAdd?.code || this.localeToAdd.code

    let details: Omit<MerchStoreShippingLocale, 'product_id'> = { locale: shippingCode, price: 0.00, currency: 'gbp', shipping_cost: 0.00, xero_tax: merchStoreTaxRates[0], shipping_type: 'unit', shipping_increment: 10, tax_exemptable: true, ship_from_location: 0, plan_id: null }

    if (useTemplate) {
      const destinationToCopy = this.product.shipping_locales.find(l => l.locale === this.destinationTemplate)

      if (!destinationToCopy) {
        throw new Error('Destination not found')
      }

      details = { ...destinationToCopy, locale: shippingCode }

      this.destinationTemplate = null
    }

    this.product.shipping_locales.push(details)
    this.localeToAdd = null
    this.zoneToAdd = null
    this.addLocaleCountrySearch = ''
    this.addLocaleZoneSearch = ''
    this.rerenderCount++
  }

  copyTemplateToDestination (destination: ShippingCode | 'any') {
    const destinationToCopy = this.product.shipping_locales.find(l => l.locale === this.destinationTemplate)

    if (!destinationToCopy) {
      throw new Error('Destination not found')
    }

    const pasteTarget = this.product.shipping_locales.find(l => l.locale === destination)

    if (!pasteTarget) {
      throw new Error('Destination not found')
    }

    const { locale, ...fieldsToCopy } = destinationToCopy

    Object.assign(pasteTarget, fieldsToCopy)

    this.destinationTemplate = null
  }

  getDestinationFromCode (code: ShippingCode | 'any'): CountryInfo | CountryZoneInfo {
    if (this.countries.length === 0 || this.zones.length === 0 || code === 'any') {
      return globalCountry
    }

    const zone = this.zones.find(z => z.code === code)

    if (!zone) {
      const country = this.countries.find(c => c.code === code)

      if (!country) {
        throw new Error(`Destination with code ${code} not found`)
      }

      return country
    }

    return zone
  }

  removeCountryWithCode (code: CountryCode | 'any') {
    this.product.shipping_locales = this.product.shipping_locales.filter(l => l.locale !== code)
  }

  addLocation (obj: MerchLocationInfo) {
    this.locationLookup = null

    if (!this.editProduct) {
      throw new Error('missing product')
    }

    if (this.editProduct.stock.find(s => s.location_id === obj.id)) {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Location already exists',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return
    }

    this.editProduct.stock.push({ location_id: obj.id, product_id: this.editProduct.id, quantity: 0, location_name: obj.name, product_name: this.editProduct.name, reserved: 0 })
  }

  formatSubscriptionPlan (plan: SubscriptionPlanInfo) {
    return `${plan.product.title}: ${plan.sku}`
  }

  debouncedSearchForAddableComponents = debounce(this.searchForAddableComponents)

  async searchForAddableComponents (term: string) {
    this.searchingComponents = true

    try {
      this.addableComponentSearch = (await Api.getStoreItems({ account_region: this.product.account_region ?? undefined }, { take: 10, term })).items
    } finally {
      this.searchingComponents = false
    }
  }

  addItemToBundle () {
    if (!this.bundleComponentToAdd) {
      throw new Error('Bundle component not selected')
    }

    const existingProduct = this.bundleComponents.find(c => c.component_product_id === this.bundleComponentToAdd?.id)

    if (existingProduct) {
      existingProduct.quantity += 1
    } else {
      this.bundleComponents.push({
        product_id: 0,
        component_product_id: this.bundleComponentToAdd.id,
        quantity: 1,
        component_product: this.bundleComponentToAdd
      })
    }

    const autocomplete = this.$refs.searchComponentAutocomplete as any
    autocomplete.setSelected('')
    this.bundleComponentToAdd = null
    this.addableComponentSearch = []
  }

  removeBundleComponent (id: number) {
    if (!this.bundleComponents) {
      throw new Error('No bundle components')
    }

    this.bundleComponents = this.bundleComponents.filter(c => c.component_product_id !== id)
    this.$forceUpdate()
  }

  doAllChildrenHaveShippingRule (locale: ShippingCode | 'any') {
    if (!this.isBundle) {
      return true
    }

    if (this.bundleComponents.length === 0) {
      return false
    }

    return this.bundleComponents.every((c) => {
      return c.component_product && c.component_product.shipping_locales.find(l => l.locale === locale)
    })
  }

  generateTaxMessage (country: MerchStoreShippingLocale) {
    let rate = 0
    const shippingDetails = getShippingDetailsForAddress(this.previewObject, createAddrFromShippingCode(country.locale))

    if (!shippingDetails) {
      return ''
    }

    if (this.isBundle) {
      const bundleTax = calculateUnitBundleTax(this.previewObject, createAddrFromShippingCode(country.locale))
      rate = bundleTax / shippingDetails.price

      return `${(rate * 100).toFixed(2)}% - ${new Intl.NumberFormat('en', { style: 'currency', currency: country.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bundleTax)} per unit`
    } else {
      const taxRate = this.isBundle ? this.nonTaxRate(country) : country.xero_tax

      if (!taxRate) {
        return ''
      }

      rate = taxRate.rate
    }

    return `${(rate * 100 - 100).toFixed(2)}% - ${new Intl.NumberFormat('en', { style: 'currency', currency: country.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(country.price * (country.xero_tax.rate * 100 - 100) / 100)} per unit`
  }
}
