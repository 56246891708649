
























































































































































































































import { Api, StoreItem, StoreItemCategory, StoreItemSubject, StripeInvoiceAccountRegion } from '@/edshed-common/api'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'
import { sleep } from '@/edshed-common/utils'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import AddMerchProduct from './components/AddMerchProduct.vue'

@Component({
  name: 'MerchProducts',
  components: { AddMerchProduct }
})
export default class MerchProducts extends Mixins(ComponentHelperBase) {
  private products: StoreItem[] = []

  private page: number = 1

  private pageSize: number = 10

  private totalResults: number = 0

  private showAddProduct: boolean = false

  private searchPhrase: string = ''

  private editingProduct: StoreItem | null = null

  private isSaveDisabled: boolean = true

  private regions = StripeInvoiceAccountRegion

  categoryFilters: StoreItemCategory[] = []
  @Watch('categoryFilters')
  categoriesChanged () {
    this.loadProducts()
  }

  themeFilters: StoreItemSubject[] = []
  @Watch('themeFilters')
  themesChanged () {
    this.loadProducts()
  }

  accountFilter: StripeInvoiceAccountRegion | null = null
  @Watch('accountFilter')
  accountChanged () {
    this.loadProducts()
  }

  mounted () {
    this.loadProducts()
  }

  get isModalReady () {
    return this.$refs.productModal !== undefined
  }

  async loadProducts () {
    try {
      const prods = await Api.getStoreItems({ subjects: this.themeFilters.length === 0 ? undefined : this.themeFilters, categories: this.categoryFilters.length === 0 ? undefined : this.categoryFilters, account_region: this.accountFilter ?? undefined }, { term: this.searchPhrase, take: this.pageSize, skip: this.pageSize * (this.page - 1) })

      this.products = prods.items
      this.totalResults = prods.total
    } catch (err) {

    }
  }

  pageChanged (page: number) {
    console.log(page)
    this.page = page
    this.loadProducts()
  }

  async searchDidChange () {
    this.page = 1

    await this.loadProducts()
  }

  needsSaveChanged (needsSave: boolean) {
    this.isSaveDisabled = !needsSave
  }

  editProduct (prod: StoreItem | null) {
    this.editingProduct = prod
    this.showAddProduct = true
  }

  async productAdded (prod: StoreItem) {
    // difficult to tell if this should be in current result set - just reload
    await sleep(300)
    this.loadProducts()
  }

  async productChanged (prod: StoreItem) {
    // difficult to tell if this should be in current result set - just reload
    await sleep(300)
    this.loadProducts()
  }

  getStockForLocation (item: StoreItem, locationId: number) {
    const location = item.stock.find(s => s.location_id === locationId)

    return location?.quantity ?? null
  }

  getReservedStockForLocation (item: StoreItem, locationId: number) {
    const location = item.stock.find(s => s.location_id === locationId)

    return location?.reserved ?? null
  }
}
