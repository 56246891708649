














































































































import { Api, SubscriptionPlanInfo, SubscriptionProductInfo, SubscriptionProductScopeInfo } from '@/edshed-common/api'
import { Currency, DomesticCurrency } from '@/edshed-common/api/types/currency'
import ComponentHelper from '@/mixins/ComponentHelper'
import moment from 'moment'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import PriceTiers from './PriceTiers.vue'

@Component({
  name: 'MerchSelectBasePlan',
  components: { PriceTiers }
})
export default class MerchSelectBasePlan extends Mixins(ComponentHelper) {
  @Prop({ required: false, default: undefined }) currency!: Currency

  activeStep: number = 0

  selectedCurrency: Currency | null = null

  domestic: boolean = false

  Currency = Currency

  DomesticCurrency = DomesticCurrency

  loadingPlans: boolean = false

  plans: SubscriptionPlanInfo[] = []

  baseProduct: SubscriptionProductInfo | null = null

  baseScope: SubscriptionProductScopeInfo | null = null

  selectedBasePlan: SubscriptionPlanInfo | null = null

  moment = moment

  @Watch('activeStep')
  stepChanged (val: number, oldVal: number) {
    if (val === 2 && oldVal < val) {
      this.getPlans()
    }

    if (oldVal >= 2 && val < 2) {
      this.baseProduct = null
      this.baseScope = null
      this.selectedBasePlan = null
    }
  }

  @Watch('baseProduct')
  productChanged () {
    this.$nextTick(() => {
      if (this.scopesForSelectedBaseProduct.length) {
        this.baseScope = this.scopesForSelectedBaseProduct[0]
      }
    })
  }

  @Watch('baseScope')
  scopeChanged () {
    this.$nextTick(() => {
      const activePlan = this.plansForScope.find(p => p.active)

      this.selectedBasePlan = activePlan ?? null

      if (!this.selectedBasePlan && this.plansForScope.length > 0) {
        this.selectedBasePlan = this.plansForScope[0]
      }
    })
  }

  get hasDomesticOption () {
    if (!this.selectCurrency) {
      return false
    }

    return DomesticCurrency.find(c => c === this.selectedCurrency) !== undefined
  }

  get basePlans () {
    return this.plans.filter(p => p.parent_id === null)
  }

  get availableProducts () {
    const map: Map<number, SubscriptionProductInfo> = new Map()

    for (const plan of this.basePlans.filter(b => this.baseProduct === null || b.product_id === this.baseProduct.id)) {
      map.set(plan.product.id, plan.product)
    }

    return Array.from(map.values())
  }

  get plansForSelectedBaseProduct () {
    if (this.baseProduct === null) {
      return []
    }

    return this.basePlans.filter(p => p.product_id === this.baseProduct!.id)
  }

  get scopesForSelectedBaseProduct () {
    const map: Map<number, SubscriptionProductScopeInfo> = new Map()

    for (const plan of this.plansForSelectedBaseProduct) {
      map.set(plan.scope.id, plan.scope)
    }

    return Array.from(map.values())
  }

  get plansForScope () {
    if (this.baseScope === null) {
      return []
    }

    return this.basePlans.filter(p => p.scope_id === this.baseScope!.id)
  }

  get isReadyToSubmit () {
    if (!this.selectedBasePlan) {
      return false
    }

    return true
  }

  close () {
    this.$emit('close')
  }

  selectCurrency (curr: Currency) {
    this.selectedCurrency = curr

    this.$nextTick(() => {
      if (this.hasDomesticOption) {
        this.activeStep = 1
      } else {
        this.activeStep = 2
      }
    })
  }

  setDomestic (val: boolean) {
    this.domestic = val

    this.activeStep = 2
  }

  selectBaseProduct (val: SubscriptionProductInfo) {
    this.baseProduct = val
  }

  unselectBaseProduct () {
    this.selectedBasePlan = null
    this.baseProduct = null
    this.baseScope = null
  }

  getCurrencySymbolFromISO (curr: Currency) {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: curr
    })

    const parts = formatter.formatToParts(1000)

    const currencySymbol = parts.find(part => part.type === 'currency')

    if (currencySymbol) {
      return currencySymbol.value
    }

    throw new Error('Could not format currency')
  }

  getCurrencyNameFromISO (curr: Currency) {
    // @ts-ignore
    const formatter = new Intl.DisplayNames(undefined, {
      type: 'currency'
    })

    const currencyTitle = formatter.of(curr)

    return currencyTitle
  }

  select () {
    this.$emit('plan-selected', this.selectedBasePlan)
    this.$emit('close')
  }

  async getPlans () {
    try {
      this.loadingPlans = true

      this.plans = await Api.getSubscriptionPlans({
        currency: this.selectedCurrency ?? undefined,
        domestic: this.domestic
      }, undefined)
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load plans',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loadingPlans = false
    }
  }
}
