




















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { MerchPurchaseItem } from '../api'

@Component({
  name: 'BundleView'
})
export default class BundleView extends Vue {
  @Prop({ required: true }) item!: MerchPurchaseItem
}
