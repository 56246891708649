























import ComponentHelper from '@/mixins/ComponentHelper'
import { UnreachableCaseError } from 'ts-essentials'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { SubscriptionPlanPriceTier, SubscriptionPlanPriceType } from '@/edshed-common/api'
import { Currency } from '@/edshed-common/api/types/currency'

@Component({
  name: 'PriceTiers'
})
export default class PriceTiers extends Mixins(ComponentHelper) {
  @Prop({ required: true }) priceType!: SubscriptionPlanPriceType

  @Prop({ required: true }) tiers!: SubscriptionPlanPriceTier[]

  @Prop({ required: true }) currency!: Currency

  @Prop({ required: false, default: null }) quantity!: number | null

  @Prop({ required: false, default: null }) min!: number | null

  @Prop({ required: false, default: null }) max!: number | null

  @Prop({ required: true }) unitAmount!: number

  textForTier (tier: SubscriptionPlanPriceTier, idx: number) {
    const previousTier = idx === 0 ? undefined : this.tiers[idx - 1]

    if (this.priceType === 'graduated') {
      if (idx === 0) {
        return `First ${tier.up_to ?? '∞'}`
      } else {
        return `Then ${previousTier!.up_to! + 1} to ${tier.up_to ?? '∞'}`
      }
    } else if (this.priceType === 'volume') {
      return `${previousTier?.up_to ?? 1} to ${tier.up_to ?? '∞'}`
    } else if (this.priceType === 'fixed') {
      if (!this.min && !this.max) {
        return ''
      } else if (!this.max) {
        return `From ${this.min}`
      } else if (!this.min) {
        return `Up to ${this.max}`
      } else {
        return `Between ${this.min} and ${this.max}`
      }
    } else {
      throw new UnreachableCaseError(this.priceType)
    }
  }

  getClassForRow (tier: SubscriptionPlanPriceTier, idx: number) {
    if (this.quantity === null) {
      return undefined
    }

    const previousTier = idx === 0 ? undefined : this.tiers[idx - 1]

    if (this.priceType === 'volume') {
      if ((previousTier?.up_to ?? 0) < this.quantity && (tier.up_to === null || this.quantity <= tier.up_to)) {
        return 'is-success'
      }
    } else if (this.priceType === 'graduated') {
      if ((previousTier?.up_to ?? 0) < this.quantity) {
        if (tier.up_to === null || tier.up_to >= this.quantity) {
          return 'is-success'
        } else {
          return 'is-warning'
        }
      }
    } else if (this.priceType === 'fixed') {
      return 'is-success'
    } else {
      throw new UnreachableCaseError(this.priceType)
    }
  }
}
